<template>
  <div>
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading"><div class="breadcrumbs"><router-link to="/admin/accounting" class="no-link">Accounting</router-link></div> Chart of Accounts</h5>
            </div>
            <div class="page-title-right">
              <div v-if="accounts.length > 0" class="form-group mr-2 mt-1 mb-0">
                <div class="checkbox checkbox-primary">
                  <label><input type="checkbox" v-model="showInactive"><span class="label-text">Show Inactive</span></label>
                </div>
              </div>
            </div>
            <div class="page-title-right">
              <button v-if="accounts.length > 0" type="button" class="btn btn-primary btn-rounded mr-2" v-b-modal.ledgerentrymodal><i class="fal fa-pencil mr-2"></i>New Entry</button>
              <button type="button" class="btn btn-default btn-rounded d-none d-md-inline-flex" @click="editAccount('edit')"><i class="fal fa-plus mr-2"></i>Add Account</button>
            </div>
          </div>

          <div class="widget-list">

            <div class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">

                  <table class="table" v-if="activeAccounts.length > 0">
                    <thead>
                      <tr><th>ID</th><th>Name</th><th>Type</th><th class="text-right">Balance</th><th></th></tr>
                    </thead>
                    <tbody>
                      <tr v-for="a in activeAccounts" :key="a.id" :class="a.active ? '' : 'text-muted'">
                        <td>{{ a.id }}</td>
                        <td><router-link :to="`/admin/accounting/ledger/${a.id}`" class="no-link">{{ a.name }}</router-link></td>
                        <td class="text-capitalize">{{ a.type }}</td>
                        <td class="text-right">{{ a.ending_balance | formatAmount(false) }}</td>
                        <td class="text-right"><button type="button" class="btn btn-link py-0 px-2" title="Edit" @click="editAccount('edit', a)"><i class="fal fa-pencil fa-fw text-primary"></i></button></td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else>
                    <div class="text-center py-4">
                      <h4 class="my-0"><p><i class="fal fa-file-invoice-dollar fa-5x text-stroke-5 text-icon-gray"></i></p><p>No accounts found</p></h4>
                      <button type="button" class="btn btn-primary btn-rounded" @click="editAccount('edit')"><i class="fal fa-plus mr-2"></i>Add Account</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

          <b-modal id="editmodal" :title="form.origID === -1 ? 'Add Account' : 'Edit Account'">
            <div class="form-group">
              <div class="checkbox checkbox-primary">
                <label><input type="checkbox" v-model="form.active"><span class="label-text">Active</span></label>
              </div>
            </div>
            <div class="form-group">
              <label class="col-form-label">ID</label>
              <input type="text" class="form-control" v-model.number="form.id" maxlength="9" @keyup.enter="saveAccount()">
            </div>
            <div class="form-group">
              <label class="col-form-label">Type</label>
              <select v-model="form.type" class="form-control custom-select">
                <option value="asset">Asset</option>
                <option value="equity">Equity</option>
                <option value="expense">Expense</option>
                <option value="income">Income</option>
                <option value="liability">Liability</option>
              </select>
            </div>
            <div class="form-group">
              <label class="col-form-label">Name</label>
              <input type="text" class="form-control" v-model="form.name" maxlength="64" @keyup.enter="saveAccount()">
            </div>
            <template v-slot:modal-footer>
              <button type="button" class="btn btn-primary btn-rounded mr-auto" @click="saveAccount()">Save Account</button>
            </template>
          </b-modal>

          <b-modal id="ledgerentrymodal" :title="ledgerform.id === '' ? 'New Ledger Entry' : 'Edit Ledger Entry'" size="xl">
            <div class="form-group col-12 col-md-8 col-lg-5 col-xl-4">
              <label class="col-form-label">Date</label>
              <b-form-datepicker v-model="ledgerform.tx_at" locale="en-US" hide-header no-highlight-today value-as-date reset-button reset-button-variant="outline-primary" label-reset-button="Clear" label-help="" menu-class="dropdown-card w-dropdown-card-medium mt-2"></b-form-datepicker>
            </div>
            <table class="table mt-5">
              <thead>
                <tr><th class="w-25">Account</th><th class="w-35">Description</th><th class="w-15 text-right">Debit (+)</th><th class="w-15 text-right">Credit (-)</th><th class="w-10"></th></tr>
              </thead>
              <tbody>
                <tr v-for="(tx, i) in ledgerform.tx" :key="`tx${i}`">
                  <td>
                    <select v-model="tx.account" class="form-control custom-select">
                      <option></option>
                      <option v-for="a in activeAccounts" :key="a.id" :value="a.id">{{ a.name }}</option>
                    </select>
                  </td>
                  <td><input type="text" class="form-control" v-model="tx.description" maxlength="128" @focus="i > 0 && !tx.description && $set(tx, 'description', ledgerform.tx[i - 1].description)" @keyup.enter="saveLedgerEntry()"></td>
                  <td><input type="text" class="form-control text-right" v-model="tx.debit" maxlength="9" @keydown="setAmount(tx, 'debit', $event)" @keyup.enter="saveLedgerEntry()"></td>
                  <td><input type="text" class="form-control text-right" v-model="tx.credit" maxlength="9" @keydown="setAmount(tx, 'credit', $event)" @keyup.enter="saveLedgerEntry()"></td>
                  <td class="text-right">
                    <button type="button" class="btn btn-link py-2 px-1" title="Add" @click="ledgerform.tx.splice(i+1, 0, {})" tabindex="-1"><i class="fal fa-plus fa-fw text-primary"></i></button>
                    <button type="button" class="btn btn-link py-2 px-1" title="Delete" @click="ledgerform.tx.splice(i, 1)" :disabled="ledgerform.tx.length === 1" tabindex="-1"><i class="fal fa-minus fa-fw text-primary" :class="ledgerform.tx.length === 1 && 'text-muted opacity-05'"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="ledgerform.files.length > 0" class="mt-4">
              <i class="fal fa-paperclip text-primary mr-2"></i>
              <span v-for="(file, i) in ledgerform.files" :key="`file${i}`" class="badge mr-2">
                <span v-if="file.id" class="scope bg-light font-weight-normal"><a :href="`${$api.defaults.baseURL}/accounting/ledger/${ledgerform.id}/${file.id}/${file.filename}`">{{ file.filename }}</a></span>
                <span v-else class="scope bg-light font-weight-normal">{{ file.filename }}</span>
                <span class="name bg-light font-weight-normal"><a class="btn-link cursor-pointer" title="Delete" @click="ledgerform.files.splice(i, 1)"><i class="fal fa-times text-primary"></i></a></span>
              </span>
            </div>
            <template v-slot:modal-footer>
              <button type="button" class="btn btn-primary btn-rounded mr-auto" @click="saveLedgerEntry()">Save Entry</button>
              <label class="btn btn-link py-1 px-0 m-0 text-primary"><i class="fal fa-paperclip mr-1"></i>Attach a file<input type="file" class="d-none" @change="uploadFiles($event.target.files)" accept="*" multiple></label>
            </template>
          </b-modal>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin, FormDatepickerPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);
Vue.use(FormDatepickerPlugin);

export default {
  data() {
    return {
      year: new Date().getFullYear(),
      showInactive: false,
      accounts: [],
      form: {
        id: 0,
        active: undefined,
        type: '',
        name: '',
      },
      ledgerform: {
        id: '',
        tx_at: new Date(),
        tx: [
          {},
        ],
        files: [],
      },
    };
  },
  computed: {
    activeAccounts() {
      if (this.showInactive) {
        return this.accounts;
      }
      const accounts = [];
      for (let i = 0; i < this.accounts.length; i += 1) {
        if (this.accounts[i].active) {
          accounts.push(this.accounts[i]);
        }
      }
      return accounts;
    },
  },
  filters: {
    formatAmount(v, includeDollarSign) {
      const amt = (Math.abs(v) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return `${v < 0 ? '(' : ''}${includeDollarSign ? '$' : ''}${amt}${v < 0 ? ')' : ''}`;
    },
  },
  watch: {
    ledgerform: {
      handler() {
        const last = this.ledgerform.tx[this.ledgerform.tx.length - 1];
        // auto set debit/credit balance
        if ((last.account || last.description) && !last.debit && !last.credit) {
          let balance = 0;
          for (let i = 0; i < this.ledgerform.tx.length; i += 1) {
            if (this.ledgerform.tx[i].amount) {
              balance += this.ledgerform.tx[i].amount;
            }
          }
          balance *= -1; // flip balance to offset current balance
          last.amount = balance;
          if (balance > 0) {
            this.$set(last, 'debit', this.$options.filters.formatAmount(balance, false));
          } else if (balance < 0) {
            this.$set(last, 'credit', this.$options.filters.formatAmount(balance * -1, false));
          }
          last.amount_auto_set = true;
        }
        // auto add trailing row
        if (last.account || last.description || last.debit || last.credit) {
          this.ledgerform.tx.push({});
        }
      },
      deep: true,
    },
  },
  methods: {
    getAccounts() {
      this.$api.get(`/accounting/accounts/all/${this.year}`)
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    editAccount(modal, a) {
      if (!a) {
        this.form.origID = -1;
        this.form.id = 0;
        this.form.active = true;
        this.form.type = '';
        this.form.name = '';
      } else {
        this.form.origID = a.id;
        this.form.id = a.id;
        this.form.active = a.active;
        this.form.type = a.type;
        this.form.name = a.name;
      }
      this.$bvModal.show(`${modal}modal`);
    },
    saveAccount() {
      this.$api.put(`/accounting/accounts/${this.form.origID !== -1 ? this.form.origID : this.form.id}`, this.form)
        .then((res) => {
          this.$bvModal.hide('editmodal');
          if (this.form.origID === -1) {
            // insert new entry
            this.accounts.push(res.data);
          } else {
            // update entry
            for (let i = 0; i < this.accounts.length; i += 1) {
              if (this.accounts[i].id === this.form.origID) {
                this.accounts[i].id = this.form.id;
                this.accounts[i].active = this.form.active;
                this.accounts[i].type = this.form.type;
                this.accounts[i].name = this.form.name;
                break;
              }
            }
          }
          // sort
          this.accounts.sort((a, b) => ((a.id > b.id) ? 1 : -1));
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    setAmount(tx, field, e) {
      if (e.key === 'Tab' || e.key === 'Enter') {
        return; // allow tab
      }
      if (tx.amount === undefined || (tx.amount < 0 && field !== 'credit') || (tx.amount > 0 && field !== 'debit') || tx.amount_auto_set) {
        this.$set(tx, 'amount', 0);
        this.$delete(tx, 'amount_auto_set');
      }
      if (tx.amount < 0) {
        this.$set(tx, 'amount', tx.amount * -1);
      }
      if (e.key >= '0' && e.key <= '9') {
        this.$set(tx, 'amount', (tx.amount * 10) + Number(e.key));
      } else if (e.key === 'Backspace' || e.key === 'Delete') {
        this.$set(tx, 'amount', Math.floor(tx.amount / 10));
      }
      if (tx.amount !== 0) {
        this.$set(tx, field, this.$options.filters.formatAmount(tx.amount, false));
      } else {
        this.$set(tx, field, undefined);
      }
      if (field === 'credit') {
        this.$set(tx, 'debit', undefined);
        this.$set(tx, 'amount', tx.amount * -1);
      } else {
        this.$set(tx, 'credit', undefined);
      }
      e.preventDefault();
    },
    saveLedgerEntry() {
      this.ledgerform.tx.splice(this.ledgerform.tx.length - 1, 1); // delete empty row
      this.$api.post('/accounting/ledger', this.ledgerform)
        .then(() => {
          this.ledgerform.id = '';
          this.ledgerform.tx = [{}];
          this.ledgerform.files = [];
          this.getAccounts();
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    uploadFiles(files) {
      for (let i = 0; i < files.length; i += 1) {
        const f = files[i];
        if (f.size > 10 * 1024 * 1024) {
          toast.danger(this, `${f.name} is too large. Maximum file size is 10 MB.`);
        } else {
          // queue file upload
          const fr = new FileReader();
          fr.onload = ((file) => (e) => {
            this.ledgerform.files.push({ filename: file.name, mimetype: file.type, data: (e.target.result.split(','))[1] });
          })(f);
          fr.readAsDataURL(f);
        }
      }
    },
  },
  async mounted() {
    try {
      if (!this.$auth.profile.site_admin) {
        this.$store.commit('error', 404);
        return;
      }
      document.title = `Accounting - Admin - ${this.$config.pretty_name}`;
      this.getAccounts();
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
  },
};
</script>
